<template>
  <div class="bg-gray-100 p-2">
    <div class="grid justify-items-center">
      <!--  l-->


      <div class="p-2 ">
        <h1 class="mb-2 text-2xl font-bold text-indigo-700 leading-loose font-sans">
          CoTech Communication Launchpad
        </h1>

        <!--Question content-->
        <div v-for="question in questionData" v-bind:key="question.keyword" class="max-w-4xl">


          <div v-if="question.feedback != ''" class="">

        <span class="py-8 text-xl italic">
          {{ question.feedback }}
        </span>
          </div>


          <div class="py-2">
            <p class="mt-2 text-xl font-bold text-gray-800 font-light">
              {{ question.content }}
            </p>
          </div>


          <div v-if="question.image">
            <img v-if="question.image !== null" :src="question.image"
                 alt="image not available" class="rounded-md shadow-lg">
          </div>


          <div v-if="question.link" class="mt-4">
            <p class="text-2xl">
              Destination
              <a :href="question.link" class="p-2 text-2xl italic border-l-4 border-r-4"> {{ question.link }}</a>
            </p>
          </div>

          <div v-if="question.tip" class="mt-4">
            <p class="text-xl italic">
              Tip:
              {{ question.tip }}
            </p>
          </div>


          <ul class="flex flex-wrap rounded-md mt-2">
            <li v-for="(answer, index) in question.answers" :key="answer.index">

              <button
                  v-on:click="getNewQuestionData({index})"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold m-2 p-2 px-4 rounded-md">
                {{ answer }}
              </button>
            </li>
          </ul>

        </div>

        <!--If ran out of questions-->
        <div v-if="!isMoreQuestions">
          <div class="box-border mt-4 mb-4">

            <button
                v-on:click="restart"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold mb-2 py-3 px-6 rounded-md">
              Restart
            </button>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import store from "@/store";


export default {
  setup() {
    let questionsData = ref(null)
    let questionData = ref(null)
    let currentQuestionId = ref(0)
    let currentQuestionType = ref("a")
    let isMoreQuestions = ref(true)

    function getSpecificQuestionData() {
      Object.values(questionsData.value).forEach(question => {
        if (question.id == currentQuestionId.value) {
          questionData.value = question.questionType.filter(questionDetail => questionDetail.option == currentQuestionType.value)
        }
      })
    }

    function getNewQuestionData(buttonName) {
      store.dispatch("saveResponse", buttonName.index)
      currentQuestionId.value++
      currentQuestionType.value = buttonName.index
      // pull another question only if there's more in store
      if (currentQuestionId.value < questionsData.value.length) {
        getSpecificQuestionData()
        if (currentQuestionId.value >= questionsData.value.length - 1) {
          isMoreQuestions.value = false
        }
      }
    }

    function restart() {
      currentQuestionId.value = 0
      currentQuestionType.value = "a"
      isMoreQuestions.value = true
      getSpecificQuestionData()
    }

    onMounted(() => {
      questionsData.value = store.getters.questions
      getSpecificQuestionData()
    })

    return {
      questionsData,
      questionData,
      currentQuestionId,
      currentQuestionType,
      getNewQuestionData,
      isMoreQuestions,
      restart
    }
  }
}
</script>

<style scoped>

</style>